<template>
    <r-e-dialog title="人员" show-footer class="layer-contract-detail" :visible.sync="dialogVisible" width="710px"
                top="10vh"
                @click-submit="clickSubmit" @click-cancel="clickCancel" @close="clickCancel">
        <el-form ref="formPublish" label-width="100px" size="mini" :model="formPublish" :rules="rules">
            <div class="flex">
                <div style="width: 400px;">
                    <div>
                        基本信息
                    </div>
                    <el-form-item label="工号" style="margin-bottom: 10px!important;">
                        <div style="width: 250px;">
                            <el-input v-model="formPublish.outsideArea"/>
                        </div>
                    </el-form-item>
                    <el-form-item label="姓名" style="margin-bottom: 10px!important;">
                        <div style="width: 250px;">
                            <el-input v-model="formPublish.outsideArea"/>
                        </div>
                    </el-form-item>
                    <el-form-item label="性别" style="margin-bottom: 10px!important;">
                        <div style="width: 250px;">
                            <el-radio-group v-model="formPublish.type">
                                <el-radio label="男">男</el-radio>
                                <el-radio label="女">女</el-radio>
                            </el-radio-group>
                        </div>
                    </el-form-item>
                    <el-form-item label="用户类型" style="margin-bottom: 10px!important;">
                        <div style="width: 250px;">
                            <el-select v-model="formPublish.outsideArea" placeholder="请选择" style="width: 100%;">
                                <el-option label="普通用户" value="普通用户"/>
                            </el-select>
                        </div>
                    </el-form-item>
                    <el-form-item label="层号" style="margin-bottom: 10px!important;">
                        <div style="width: 250px;">
                            <el-input v-model="formPublish.outsideArea"/>
                        </div>
                    </el-form-item>
                    <el-form-item label="房间号" style="margin-bottom: 10px!important;">
                        <div style="width: 250px;">
                            <el-input v-model="formPublish.outsideArea"/>
                        </div>
                    </el-form-item>
                    <el-form-item label="开始时间" style="margin-bottom: 10px!important;">
                        <div style="width: 250px;">
                            <el-date-picker v-model="formPublish.outsideArea" value-format="yyyy-MM-dd HH:mm:ss"
                                            type="datetime" format="yyyy-MM-dd HH:mm:ss" placeholder="选择日期时间"
                                            style="width: 100%;" />
                        </div>
                    </el-form-item>
                    <el-form-item label="结束时间" style="margin-bottom: 10px!important;">
                        <div style="width: 250px;">
                            <el-date-picker v-model="formPublish.outsideArea" value-format="yyyy-MM-dd HH:mm:ss"
                                            type="datetime" format="yyyy-MM-dd HH:mm:ss" placeholder="选择日期时间"
                                            style="width: 100%;" />
                        </div>
                    </el-form-item>
                    <el-form-item label="访问权限" style="margin-bottom: 10px!important;">
                        <div style="width: 250px;">
                            <el-checkbox v-model="checked">设备管理员</el-checkbox>
                        </div>
                    </el-form-item>
                    <div>
                        认证设置
                    </div>
                    <el-form-item label="认证类型" style="margin-bottom: 10px!important;">
                        <div style="width: 250px;">
                            <el-radio-group v-model="formPublish.type">
                                <el-radio :label="1">同设备</el-radio>
                                <el-radio :label="2">自定义</el-radio>
                            </el-radio-group>
                        </div>
                    </el-form-item>
                </div>
                <div>
                    <upload-avatar @on-success="handleUploadSuccess" :uuid="formPublish.ichnography"/>
                </div>
            </div>
        </el-form>
    </r-e-dialog>
</template>

<script>
    export default {
        name: "dialog-save-personnel",
        data() {
            return {
                dialogVisible: false,
                formPublish: {},
                rules: {
                    lastWater: [{required: true, message: '上次水表读数不能为空', trigger: 'blur'}],
                    lastElect: [{required: true, message: '上次电表读数不能为空', trigger: 'blur'}],
                    water: [{required: true, message: '当前水表读数不能为空', trigger: 'blur'}],
                    elect: [{required: true, message: '当前电表读数不能为空', trigger: 'blur'}],
                    createdTime: [{required: true, message: '当前抄表时间不能为空', trigger: 'change'}],
                    lastTime: [{required: true, message: '上次抄表时间不能为空', trigger: 'change'}]
                },
                loading: true,
                loadingOptions: {
                    lock: true,
                    text: "Loading...",
                    spinner: "el-icon-loading",
                    background: "rgba(0, 0, 0, 0.7)"
                },
                checked: false
            }
        },
        props: {
            equipmentData: {
                type: Object,
                default: () => ({})
            }
        },
        components: {
            uploadAvatar: () => import("@/components/Upload/upload-avatar"),
        },
        methods: {
            async openDialog() {
                let that = this;
                this.dialogVisible = true;
            },
            clickSubmit() {
                let that = this;
                this.$refs["formPublish"].validate((valid) => {
                    if (valid) {
                        console.log(1)
                    }
                })
            },
            clickCancel() {
                this.$emit("handleSearch", false);
                this.dialogVisible = false;
            },
            async getHydropowerBill() {
                let that = this;
            },
            handleUploadSuccess({info}) {
                // 上传图片
                this.uploadUuid = info.uuid;
            },
        },
        watch: {}
    }
</script>

<style scoped>

</style>